@import "~bootstrap/scss/variables";
@import "variables";
@import "~bootstrap/scss/mixins/box-shadow";
@import "~bootstrap/scss/mixins/border-radius";

.popup-control, .form-select {
  &:not(.form-control) {
    .react-select__control {
      @extend .form-control;

      display: flex;
      padding: 0;

      &:focus-within {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        @if $enable-shadows {
          @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        } @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow: $input-focus-box-shadow;
        }
      }
    }

    .react-select__menu {
      z-index: 5;
    }
  }
}

.input-group {
  .popup-control:not(.form-control) {
    flex: 1 1 0;

    &:not(:last-child) {
      .form-control {
        @include border-right-radius(0);
      }
    }

    &:not(:first-child) {
      .form-control {
        @include border-left-radius(0);
      }
    }
  }
}
