// If you want to override variables do it here
@import "variables";
// Import styles
@import "~@coreui/coreui/scss/coreui.scss";
@import "~react-pdf/dist/esm/Page/AnnotationLayer.css";
// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';
// If you want to add something do it here
@import "custom";
@import "overflow";
@import "component-styles";
// ie fixes
@import "ie-fix";
// StimulSoft
@import "stimulsoft.designer.office2013.whiteteal.css";
@import "stimulsoft.viewer.office2013.whiteteal.css";
// Custom styles
@import "home";
@import "simple-loader";
@import "profile-page";
// User Branches
@import "branches";
@import 'user-branches-ui';
// Tasks
@import 'tasks';
// ImageView
@import 'images';
@import "book";
@import "library";
@import "branch-user-selection.scss";
// React-sortable-hoc
@import "sortable-hoc";
@import "production";

@import "react-pdf";
@import "react-select";

//ATM monitoring
@import "atm_monitoring";