.of-auto {
  overflow: auto;
}

.of-x-auto {
  overflow-x: auto;
}

.of-y-auto {
  overflow-y: auto;
}

.of-hidden {
  overflow: hidden;
}

.of-x-hidden {
  overflow-x: hidden;
}

.of-y-hidden {
  overflow-y: hidden;
}

.of-visible {
  overflow: visible;
}

.of-x-visible {
  overflow-x: visible;
}

.of-y-visible {
  overflow-y: visible;
}
