$app-header-bg: #225782;
$element-color: #9EB5C9;
$input-border-color: $element-color;
$input-group-addon-border-color: $element-color;
$element-button-color: #4086bf;
$element-button-text-color: #fafafa;
$element-close-button-color: #e08d52;
$modal-xl: 1000px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
