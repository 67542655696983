.book {
  table, th, td {
    border: 1px solid grey;
  }

  th, td {
    padding: 5px;
  }
}

.rstcustom__row {
  height: 100%;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}

.rstcustom__rowLabel {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 1 1 auto;
  padding-right: 20px;
}
.rstcustom__toolbarButton .btn-link:hover {
    background-color: #20a8d8;
    color: #fff;
}
.rstcustom__toolbarButton .btn-link:not(:last-child) {
    font-size: 16px;
    line-height: 18px;
}
.rstcustom__toolbarButton .btn-link {
    text-decoration: none;
}