@import "~bootstrap/scss/variables";

@keyframes move-arrow-up {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, -20px);
  }
}

@keyframes move-arrow-down {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 20px);
  }
}

.stage-drag-handler {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  color: white;
  background-color: $gray-200;
  width: 25px;
  height: 100%;

  .move-up, .move-down {
    display: none;
    font-size: 0.7em;
  }

  &:hover {
    background-color: $gray-300;

    .move-up {
      display: flex;
      animation: move-arrow-up .6s linear 0s infinite alternate;
    }

    .move-down {
      display: flex;
      animation: move-arrow-down .6s linear 0s infinite alternate;
    }
  }

  &:active, &:focus {
    background-color: $info;
  }
}

.card-body.p-0 div[data-react-beautiful-dnd-draggable]:not(.dragging):last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

div[data-react-beautiful-dnd-draggable] {
  background-color: white;

  &.dragging {
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
  }
}

.stage-processes-placeholder {
  min-height: 5.5rem;
  min-width: .25rem;
}
