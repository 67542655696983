.library-history {
  table {
    tr td {
      &:not(:last-child) {
        vertical-align: top;
      }
      &:last-child > span {
        white-space: pre-line !important;
      }
    }
  }
}