@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "variables";

html, body, #root {
  display: block;
  height: 100%;
}

body {
  background-color: $app-header-bg;
}

#root {
  background-color: $body-bg;
}

#root > div {
  display: block;
  height: 100%;
}

#root > .container-fluid {
  position: fixed;
  inset: 0 0 0 0;
  padding: 3rem;
  background-color: rgba(255,255,255,0.7);
  z-index: 100000;
}

.app {
  min-height: unset;
  height: 100%;
}

input[readonly], .form-control[readonly] {
  &:not([disabled]) {
    background-color: $white;
  }
}

.form-control {
  overflow: hidden;
  text-overflow: ellipsis;
}

.position-relative.form-check .checkbox {
  position: relative;
}

.position-relative.form-check .form-check-input {
  top: 0;
}

.app .app-header {
  border: 0;
  background-color: $app-header-bg;
  box-shadow: 0 4px 7px 0 rgba(10, 10, 10, .3);

  .nav-link {
    color: white;
    font-weight: 600;

    &:focus {
      color: lighten($info, 1);
    }

    &:hover, &:active {
      color: $info;
    }
  }

  .navbar-toggler {
    color: white;

    &:focus {
      color: lighten($info, 1);
    }

    &:hover, &:active {
      color: $info;
    }
  }
}

.auto-complete-dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.app-header-title {
  display: block;
  width: 300px;
  text-align: center;
  position: absolute;
  left: 50%;
  color: white;
  margin-left: -150px;
  font-size: 16pt;
  font-weight: 500;
}

.app-body {
  align-items: stretch;
  overflow: hidden;

  .main {
    display: flex;
    flex-direction: column;
  }
}

.main .app-page {
  flex: 1;
  background: #f0f3f5;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.app-page > .container-fluid {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
}

.table.data-table {
  thead {
    color: white;
    background-color: #008f95;

    th {
      text-align: center;
    }
  }
}

.data-base-card {
  position: relative;
  margin-bottom: 0;
  flex-grow: 1;
  overflow: auto;
}

.container-fluid > .data-base-card {
  border: 0;
  border-radius: 0;

  & > .card-header {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    z-index: 1000;
  }
}

.base-form {
  .form-group {
    @include media-breakpoint-up(sm) {
      &.row > .col-form-label:first-child {
        text-align: right;
      }
    }

    > label:first-child {
      font-weight: bold;
      color: #30415d;
    }

    .base-label {
      border: 1px solid $element-color;
      border-radius: 4px;
      min-height: 35px;
      padding-left: 10px;
    }
  }

  .form-control {
    color: #23282F;

    &.form-check-input {
      margin-right: 5px;
    }
  }
}

.td-form-select {
  min-width: 7vw;
}

.td-form-popup {
  min-width: 10vw;
}

.the-date-picker {
  .dp-no-arrows {
    border: 1px solid $element-color;
    border-radius: 4px;
    min-height: 35px;
    text-align: center;
    padding-right: 16px;
  }

  button.react-datepicker__close-icon::after {
    background-color: $element-close-button-color;
  }

  .dp-arrows {
    display: flex;
    min-height: 35px;

    button[disabled] {
      background-color: rgba($element-color, 0.5);
      color: rgba($element-button-color, 0.9);
    }

    .arrow {
      padding: 0;
      background-color: $element-button-color;
      color: $element-button-text-color;
      border-width: 0;

      &.arrow-left {
        border-radius: 4px 0 0 4px;
      }

      &.arrow-right {
        border-radius: 0 4px 4px 0;
      }
    }

    .react-datepicker__input-container {
      border-width: 1px 0 1px 0;
      border-color: $element-color;
      border-style: solid;

      input {
        border-width: 0;
        min-height: 35px;
        padding-right: 16px;
        text-align: center;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 9;
  }
}

.react-datepicker__time-list {
  padding-inline-start: 0;
}

.react-datepicker__time-list::-webkit-scrollbar {
  display: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-right: 0;
}

.form-group.base-read-only {
  border-bottom: 0;
}

.aside-menu-show .aside-menu {
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3);
}

.input-group > .form-check-label {
  margin-right: 5px;
}

.page-filters {
  margin: -20px -20px 20px;
  background-color: #f0f3f5;
  border-bottom: 1px solid #c2cfd6;
}

.filter-title {
  background: linear-gradient(to right, #6d7993, #8995af);
  padding: 5px 20px 5px 15px;
  color: white;
  font-weight: 600;
}

.filter-container {
  padding: 10px 20px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
}

.filter-item {
  display: flex;
  flex-direction: column;

  &.span-2 {
    grid-column-end: span 2;
  }

  &.span-3 {
    grid-column-end: span 3;
  }
}

.filter-input {
  .tire {
    color: #737d8c;
    margin: 0 10px;
  }
}

.report-modal {
  .modal-dialog {
    margin: 0;
    max-width: unset;
    height: 100%;

    .modal-content {
      height: 100%;
      border: 0;
    }
  }
}

.toastify {
  z-index: 9999 !important;
}

.radio-group.input-group {
  flex-wrap: wrap;

  > .form-check-label {
    padding: 4px 0 4px 20px;
  }
}

.form-group fieldset.radio-inline.form-group {
  display: inline;
  margin-bottom: 0;
  padding-top: 7px;
}

.card-header {
  .card-actions {
    a, button {
      min-width: 50px;
      width: unset;
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }
  }
}

.transfer-status-draft, .move-status-draft {
  color: #000;
}

.transfer-status-send, .move-status-send {
  color: #000;
}

.transfer-status-sent, .move-status-sent, .invoice-status-draft {
  color: #f86c6b;
}

.transfer-status-receive, .transfer-status-received, .move-status-receive, .move-status-received {
  color: #20a8d8;
}

.transfer-status-preinvalid, .transfer-status-invalid, .move-status-preinvalid, .move-status-invalid {
  color: #ffc107;
}

.transfer-bg-in, .move-bg-in {
  background-color: #e6ffec;
}

.transfer-bg-out, .move-bg-out {
  background-color: #ffe6e6;
}

.invoice-status-received, .invoice-status-receive {
  color: #4dbd74;
}

.sidebar .nav-dropdown-toggle::before {
  position: absolute;
  top: 50%;
  right: $sidebar-nav-link-padding-x;
  display: block;
  width: 8px;
  height: 10px;
  padding: 0;
  margin-top: -4px;
  content: "";
  background-image: $sidebar-nav-dropdown-indicator;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px;
  transition: transform .3s;
}

.modal-content > .card:last-child {
  margin-bottom: 0;
}

.text-bold, tr.text-bold td, td.text-bold {
  font-weight: bold;
}

.text-black, tr.text-black td, td.text-black {
  color: #000;
}

.text-big {
  font-size: 20pt;
}

.data-table {
  &.table-hover > tbody > tr {
    cursor: default;
  }

  &.table-bordered {
    td, th {
      border-color: #eee;

      input[type=checkbox] {
        cursor: pointer;
      }
    }
  }

  td .btn-xs {
    padding: 1px 4px;
  }
}

td {
  .nav-link {
    padding: 0;
  }

  .form-group {
    margin: 0;
  }

  &.width-20 {
    width: 20%;
  }
}

.radio-inline .form-check {
  display: inline-block;
  margin-right: 15px;
}

@mixin flex-fill-max-height-100 {
  flex: 1;
  max-height: 100%;
}

.modal-dialog.fixed-header {
  max-height: calc(100% - 56px);
  display: flex;

  > .modal-content {
    @include flex-fill-max-height-100;

    > .modal-body {
      @include flex-fill-max-height-100;

      > .card {
        margin-bottom: 0;

        @include flex-fill-max-height-100;

        .card-body {
          overflow: auto;

          @include flex-fill-max-height-100;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl {
    max-width: $modal-xl;
  }
}

.bg-muted {
  color: #a1a1a1;
  background-color: #e3e3e3;
}

.card-section-divider {
  margin: 8px -20px;
  padding: 8px 20px;
}

.app-loading.card-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.debt-positive {
  background-color: #e6ffec;
}

.debt-negative {
  background-color: #ffe6e6;
}

.pin-num-pads {
  padding: 30px;
  border: 1px solid #20a8d8;

  .col {
    .num-btn {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;

      .num-text {
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #20a8d8;
        color: #20a8d8;
        cursor: pointer;

        &:hover, &:focus, &:active {
          background-color: rgba(32, 168, 216, 0.1);
        }

        &:active {
          background-color: rgba(32, 168, 216, 0.2);
        }

        user-select: none;
      }
    }

    .num-btn-danger {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;

      .num-text {
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #f86c6b;
        color: #f86c6b;
        cursor: pointer;

        &:hover, &:focus, &:active {
          background-color: rgba(248, 108, 107, 0.1);
        }

        &:active {
          background-color: rgba(248, 108, 107, 0.2);
        }

        user-select: none;
      }
    }
  }
}

.row.selection-tools {
  min-height: 32px;
}

.outline-none {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user-select-none {
  user-select: none;
}

.popup-control.popup-placeholder {
  color: $gray-600;
}

.padding-vertical {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding {
  padding: 8px;

  &.jumbotron {
    padding: 16px;
  }
}

.padding-horizontal-15 {
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 991.99px) {
  .sidebar-mobile-show .sidebar {
    top: 55px;
    bottom: 0;

    .nav {
      min-height: auto;
    }
  }
}

.main {
  @media (max-width: 768px) {
    .container-fluid {
      padding: 0;
    }
  }

  .card-header .card-actions {
    position: relative;
    top: unset;
    left: unset;
    min-height: 45px;
    margin: -12px -20px -13px;
    display: flex;
    flex-wrap: wrap;

    a, button {
      margin: -1px 0 0 -1px !important;
      border: 1px solid #c2cfd6;
    }
  }
}

.rounded-item {
  border: 1px solid #c2cfd6;
  border-radius: 8px;
  padding: 1px 8px;
  margin: 0 8px 8px 0;
  display: inline-block;
}

.link-white {
  color: white;
}

.hide-all-nav {
  #root > div {
    height: 100%;
  }

  .app {
    overflow: hidden;

    .app-header.navbar {
      margin-top: -55px;
    }

    .app-body {
      margin: 0;

      .sidebar {
        margin-left: -200px;
      }

      > .sidebar + .main {
        margin-left: 0;
        margin-right: 0;
      }

      .app-page {
        display: flex;
        flex-direction: column;

        .container-fluid {
          display: flex;
          flex: 1;
          padding: 0;
          margin: 0;
        }
      }
    }

    .app-footer {
      margin-bottom: -50px;
    }
  }

  &.sidebar-minimized {
    .app-body {
      .sidebar {
        margin-left: -50px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .hide-all-nav {
    .app {
      .app-body {
        margin-top: 0;

        .sidebar {
          margin-left: -220px;
        }
      }
    }
  }
}

.ready-agents-page {
  background-color: #8b97a7;
  flex: 1;

  &.ready-agents-page-scrollable {
    overflow: auto;
  }

  &.ready-agents-page-no-scroll {
    display: flex;
    flex-direction: column;

    .row {
      flex: 1;

      > div {
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .ready-header {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 10px 5px;

    &.top {
      align-items: flex-start;
    }

    .agent-user-input {
      position: absolute;
      top: 0px;
      left: calc(50% - 100px);
      width: 200px;
      background-color: white;
      font-size: 1.4em;
      font-weight: 600;
      border-radius: 0 0 5px 5px;
      box-shadow: 2px 2px 5px 2px #4d5666;
      padding: 2px;
      text-align: center;
      color: #CD5360;
      transition: top .2s linear;

      &.hide {
        top: -40px;
      }
    }

    .header-btn-group {
      display: flex;

      .go-back {
        display: flex;
        border-radius: 5px;
        background-color: darken($element-button-color, 10%);
        box-shadow: 1px 1px 3px 1px #808080;
        padding: 5px;
        margin-right: 5px;
        color: white;
        cursor: pointer;

        &:hover {
          background-color: darken($element-button-color, 5%);
        }

        .name {
          display: flex;
          font-size: 1.2em;
          font-weight: 600;
          justify-content: center;
          align-items: center;

          &.pad-right {
            padding-right: 15px;
          }

          &.pad-left {
            padding-left: 10px;
          }
        }

        .symbol {
          display: flex;
          font-size: 1.8em;
          line-height: 1em;
          font-weight: 600;
          border-left: 1px solid hsla(207, 50%, 80%, 1);
          margin-left: 15px;
          padding: 0 10px 0 15px;
        }
      }
    }

    .header-info-name {
      font-size: 2.5em;
      font-weight: 600;
      color: white;

      &.smaller {
        font-size: 1.2em;
      }

      &.shift-left {
        margin-left: -130px;
      }
    }

    .header-info-date {
      font-size: 1.5em;
      font-weight: 500;
      color: #e6e6e6;
    }

    .header-nomenclature {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
      background-color: #743e56;
      padding: 5px 25px 15px;
      border-radius: 4px;
      box-shadow: 1px 1px 4px 1px #5c6570;
      color: white;

      .agent-name {
        font-size: 3em;
        font-weight: 600;
        border-bottom: 2px solid #8c566e;
      }

      .nomen-info {
        display: flex;
        flex: 1;
        flex-direction: row;
        font-size: 2em;
        justify-content: space-between;

        .nomen-title {
          font-weight: 600;
        }

        .nomen-quantity {
          font-weight: 700;
        }
      }
    }
  }

  .ready-agents-body {
    > div {
      display: grid;
    }

    .agent-name, .nomenclature-name {
      border-radius: 5px;
      background-color: white;
      box-shadow: 1px 1px 3px 1px #4d5666;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px;
      padding: 10px 0;

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }

      .index {
        font-size: 2em;
        font-weight: 600;
        color: #212121;
        line-height: 1em;
      }

      .name {
        font-size: 1.4em;
        font-weight: 500;
        text-align: center;
        line-height: 1.2em;
      }
    }
  }

  .quantity-input, .tara-input {
    background-color: #fff;
    margin: 8px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid #7b8387;
    color: #c2cfd6;

    &.active-input {
      border-color: #000;
      color: #000;
    }

    .ready-input-field {
      font-size: 72px;
      line-height: 72px;
      min-height: 72px;
      text-align: right;
    }
  }

  .agent-tara-history {
    margin-top: 104px;
    padding: 15px;

    .tara-title {
      font-size: 2em;
      text-align: center;
      color: ghostwhite;
    }

    .ready-history-item {
      border-bottom: 1px solid #7b8387;
      background-color: ghostwhite;

      .ready-history-agent-name {
        font-weight: bold;
      }

      .ready-history-nomenclature-name {
        font-weight: 300;
        font-size: 24px;
        line-height: 32px;
        overflow: hidden;
        padding-left: 15px;
      }

      .ready-history-quantity {
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        overflow: hidden;
      }

      .row > div:not(:first-child) {
        display: flex;
        align-items: center;
      }
    }
  }

  button.btn.btn-primary {
    background-color: #008b95;
    border-color: whitesmoke;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 1.1em;

    &:hover {
      background-color: darken(#008b95, 3%);
    }
  }
}

@include media-breakpoint-down(sm) {
  .ready-agents-page {
    &.ready-agents-page-no-scroll {
      display: block;
      overflow-x: hidden;
      overflow-y: auto;

      .row {
        > div {
          overflow-x: hidden;
          overflow-y: hidden;
        }
      }
    }
  }
}

.agent-report-detail-view > .form-group {
  > .col-form-label:last-child {
    border-bottom: 1px solid #c2cfd6;
    font-weight: bold;
  }
}

.CodeMirror {
  height: 500px !important;

  &.CodeMirror-fullscreen {
    z-index: 2000;
    height: auto !important;
  }

}

.fullscreen {
  z-index: 2000 !important;
}

input.form-control[type=button] {
  text-align: start;
}

.btn-list {
  margin-left: -2px;
  margin-right: -2px;

  .btn {
    margin: 2px;
  }
}

.margin-bottom {
  margin-bottom: 8px;
}

//noinspection CssReplaceWithShorthandSafely
.no-margin {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.report-route-table {
  tr td {
    min-width: 30px;
  }

  td, th {
    &.route-contractor {
      width: 150px;
    }

    &.contractor-address {
      width: 250px;
    }

    &.contractor-contact-person {
      min-width: 100px;
    }

    &.contractor-phone {
      min-width: 100px;
    }
  }
}

.report-route-container {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
}

.rr-row {
  display: inline-flex;
  white-space: nowrap;
  position: relative;
}

.report-route-left {
  position: sticky;
  left: 0;
  width: 320px;
  display: inline-flex;
  background-color: #ffffff;
}

.report-route-right {
  white-space: nowrap;
  display: inline-flex;
}

.rr-contractor-name,
.rr-contractor-address,
.rr-contractor-person,
.rr-contractor-phone,
.rr-contractor-days,
.rr-contractor-days-total {
  color: #000000;
  padding: 4px;
  overflow: hidden;
  white-space: pre-wrap;
  display: inline-block;
  border-right: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}

.rr-row:first-child {
  .rr-contractor-name,
  .rr-contractor-address,
  .rr-contractor-person,
  .rr-contractor-phone,
  .rr-contractor-days ,
  .rr-contractor-days-total {
    font-weight: bold;
    text-align: center;
    border-top: 1px solid #aaa;
  }

  .rr-date {
    border-top: 1px solid #aaa;
  }
}

.rr-row:not(:first-child) {
  .rr-date {
    font-size: 70%;
  }
}

.rr-contractor-name {
  width: 120px;
  border-left: 1px solid #aaa;
  cursor: pointer;
  user-select: none;
}

.rr-contractor-address {
  width: 200px;
}

.rr-contractor-person {
  width: 120px;
}

.rr-contractor-phone {
  width: 120px;
}

.rr-contractor-days {
  white-space: nowrap;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.rr-contractor-days-total {
  width: 120px;
  text-align: center;
}

.rr-contractor-days-title {
  padding: 4px;
}

.rr-contractor-dates {
  flex: 1;
  display: flex;
  flex-direction: row;
  font-weight: normal;

  &.text-bold {
    font-weight: bold;
  }

  .rr-date {
    width: 50px;
    padding: 2px;
    cursor: pointer;
    user-select: none;

    &:not(:first-child) {
      border-left: 1px solid #aaa;
    }
  }
}

.sidebar {
  background: #30415D;

  .nav-item.user-info, .nav-item.side-logout {
    display: none;
  }

  .nav {
    .side-main a {
      background: #30415D;

      .nav-icon {
        color: #73818f;
      }

      &:hover {
        background: #20a8d8;

        .nav-icon {
          color: #fff;
        }
      }
    }

    .nav-title {
      text-align: center;
      font-size: 1em;
      border-bottom: 1px solid rgba(255, 255, 255, .2);
      border-top: 1px solid rgba(255, 255, 255, .2);
    }

    .nav-item.nav-dropdown.open {
      .nav-link.active {
        background: transparent;
        color: #ff6a5c;
        border-left: 2px solid #ff6a5c;

        .nav-icon {
          color: #ff6a5c;
        }

        &:hover {
          background: #20a8d8;
          color: #fff;
          border-left: 2px solid rgba(0, 0, 0, 0);

          .nav-icon {
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .app-header .app-header-title {
    font-size: 12pt !important;
    font-weight: 400;
  }
  .footer-fixed .sidebar {
    height: calc(100vh - 105px)
  }
  .sidebar {
    .nav-item.user-info, .nav-item.side-logout {
      display: block;
    }

    .nav-item.user-info {
      .nav-link {
        font-weight: bold;
      }
    }

    .nav-item.side-logout {
      background-color: #f86c6b;

      .nav-icon {
        color: #efefef;
      }
    }
  }
}

.order-categories-card, .order-branches-card, .order-contractors-card {
  margin-bottom: 8px;
  border: 1px solid #c8ced3;
}

.order-categories-card-title, .order-branches-card-title, .order-branch-item-title,
.order-contractors-card-title, .order-contractor-item-title {
  background-color: #f0f3f5;

  &[aria-expanded=true] {
    border-bottom: 1px solid #c8ced3;
  }

  .col-auto {
    display: flex;

    .btn {
      border: none;
      border-radius: unset;
    }
  }
}

.order-categories-card-title-text, .order-branches-card-title-text, .order-branch-item-title-text,
.order-contractors-card-title-text, .order-contractor-item-title-text {
  padding: 8px 16px;
  cursor: pointer;
}

.order-branch-item-title-units {
  padding: 8px 16px;
}

.order-categories-list, .order-branches-list, .order-contractors-list {
  padding: 4px 8px;
  margin: 4px 0;
}

.order-category-item, .order-branch-item, .order-contractor-item {
  margin: 4px 0;
  border: 1px solid #c8ced3;
}

.order-branch-item .order-branch-item-body, .order-contractor-item .order-contractor-item-body {
  padding: 8px 8px 4px 8px;
}

.order-category-item > div:first-child {
  padding: 8px 16px;
  cursor: pointer;
  background-color: #f0f3f5;

  &[aria-expanded=true] {
    border-bottom: 1px solid #c8ced3;
  }
}

.order-nomenclature-item {
  padding: 8px 16px;
  border-bottom: 1px solid #eee;
  height: 100%;
}

@media (min-width: 992px) {
  .order-nomenclatures-list {
    > div:not(:nth-child(3n)) {
      .order-nomenclature-item {
        border-right: 1px solid #eee;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .order-nomenclatures-list {
    > div:nth-child(odd) {
      .order-nomenclature-item {
        border-right: 1px solid #eee;
      }
    }
  }
}

.audit-users-list, .audit-contractors-list, .field-support-users-list, .field-support-contractors-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}

.audit-user-item, .audit-contractor-item, .field-support-user-item, .field-support-contractor-item {
  cursor: pointer;
  user-select: none;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 8px;

  &:hover {
    background-color: #fafafa;
  }

  &:active {
    background-color: #f3f3f3;
  }

  @media (min-width: 992px) {
    width: 25%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.audit-contractor-address, .field-support-contractor-address {
  font-size: 12px;
  line-height: 12px;
}

.row.audit-head, .row.field-support-head {
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;

  > div {
    padding: 4px 8px;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
  }
}

.audit-fields, .field-support-fields {
  > div:nth-child(even) {
    background-color: #fafafa;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.empty-unread-messages {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  text-align: center;
  font-family: sans-serif;
}

.label-text-danger, .label-text-muted, .label-text-warning, .label-text-info, .label-text-success,
.rr-date-text-danger, .rr-date-text-muted, .rr-date-text-warning, .rr-date-text-info, .rr-date-text-success {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > span {
    margin-right: 4px;
  }
}

.label-text-danger, .label-text-muted, .label-text-warning, .label-text-info, .label-text-success {
  padding: 0 4px;
  border-radius: 4px;
}

.label-text-danger {
  border: 1px solid #f86c6b;
}

.label-text-muted {
  border: 1px solid #73818f;
}

.label-text-warning {
  border: 1px solid #ffc107;
}

.label-text-info {
  border: 1px solid #63c2de;
}

.label-text-success {
  border: 1px solid #4dbd74;
}

.label-text-danger, .rr-date-text-danger {
  background-color: rgba(248, 108, 107, 0.3);
}

.label-text-muted, .rr-date-text-muted {
  background-color: rgba(115, 129, 143, 0.3);
}

.label-text-warning, .rr-date-text-warning {
  background-color: rgba(255, 193, 7, 0.3);
}

.label-text-info, .rr-date-text-info {
  background-color: rgba(99, 194, 222, 0.3);
}

.label-text-success, .rr-date-text-success {
  background-color: rgba(77, 189, 116, 0.3);
}

.margin-vertical {
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-footer, .btn-toolbar {
  > .btn + .btn {
    margin-left: 4px;
  }
}

.title-flex .modal-title {
  flex: 1;
}

.row > .card {
  flex: 1;
  margin: 0 15px;
}

.qr-identity {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.rc-time-picker-input, .rc-time-picker-panel-input {
  @extend .form-control;
}

.rc-time-picker {
  display: block;
}

.rc-time-picker-panel-input-wrap {
  padding: 0;
  border: none;
  border-radius: 0;
}

.rc-time-picker-panel-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rc-time-picker-panel-inner {
  border: none;
  border-radius: $input-border-radius;
}

.rc-time-picker-panel-combobox {
  border: solid $input-border-color;
  border-width: 0 $input-border-width $input-border-width $input-border-width;
  border-bottom-left-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;
  position: relative;

  &:after {
    display: block;
    content: ' ';
    clear: both;
  }
}

.d-flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-time-picker-clear {
  top: 50%;
  transform: translateY(-50%);
  @extend .d-flex-center-center;
}

.rc-time-picker-clear-icon {
  @extend .d-flex-center-center;

  &:after {
    text-decoration: none !important;
    @extend .d-flex-center-center;
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color}-hover:hover, .bg-#{$color}-focus:focus {
    background-color: $value !important;
  }
  .bg-hover-#{$color}:hover, .bg-focus-#{$color}:focus {
    background-color: darken($value, 5%) !important;
  }
  .bg-#{$color}-focus:focus, .bg-focus-#{$color}:focus {
    outline: none;
  }
  .bg-#{$color}-active:active {
    background-color: darken($value, 5%) !important;
  }
  .bg-active-#{$color}:active {
    background-color: darken($value, 10%) !important;
  }

  .border-#{$color}-hover:hover, .border-#{$color}-focus:focus {
    border-color: $value !important;
  }
  .border-hover-#{$color}:hover, .border-focus-#{$color}:focus {
    border-color: darken($value, 5%) !important;
  }
  .border-#{$color}-focus:focus, .border-focus-#{$color}:focus {
    outline: none;
    box-shadow: 0 0 0 .2rem rgba($value, .25);
  }
  .border-#{$color}-active:active {
    border-color: darken($value, 5%) !important;
  }
  .border-active-#{$color}:active {
    border-color: darken($value, 10%) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.report-template-viewer .stiJsViewerReportPanel {
  position: relative !important;
  top: 0 !important;
}

.data-base-card {
  .stiJsViewerToolBar {
    position: sticky;
    top: 57px;
  }

  .stiJsViewerReportPanel {
    overflow-x: auto !important;
  }
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: .25rem;
}

.rounded-2 {
  border-radius: .5rem;
}

.rounded-3 {
  border-radius: 1rem;
}

.rounded-4 {
  border-radius: 1.5rem;
}

.rounded-5 {
  border-radius: 3rem;
}

.min-vh-10 {
  min-height: 10vh;
}

.min-vh-25 {
  min-height: 25vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-75 {
  min-height: 75vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-gray {
  color: $gray-500;
}

.trace-nodes-wrapper {
  position: relative;
  padding-bottom: 5rem;
  min-height: 75vh;

  & > div:first-child {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    & > div:first-child {
      flex-grow: 1;
    }
  }
}

.trace-node-item-active {
  font-weight: bold;
  background-color: darken($light, 3%);
  outline: none;
}

.trace-node-item {
  display: block;
  border: 0;
  border-radius: 0;
  padding: .25rem .5rem;
  background-color: unset;
  text-decoration: none;
  text-align: start;
  color: $dark;

  &:hover {
    background-color: $light;
    cursor: pointer;
  }

  &:focus, &:active {
    @extend .trace-node-item-active;
  }
}

@include media-breakpoint-down(xs) {
  .trace-node-details {
    position: fixed;
    z-index: 1000;
    overflow: auto;

    right: 0;
    bottom: 0;
    left: 0;
    padding: 4rem .5rem;
    max-height: calc(100vh - 1rem);

    & > div {
      box-shadow: $modal-content-box-shadow-xs;
    }
  }
}

.inherit-color {
  color: inherit;
}

a.inherit-color:hover {
  text-decoration: none;
  @extend .inherit-color;
}

.sticky-header {
  @extend .sticky-top;
  @supports (position: sticky) {
    right: 0;
    left: 0;
    border-radius: 0;
    z-index: 1000;
  }
}

.flex-fill-equal {
  flex: 1 1 0;
}

@each $size, $length in $sizes {
  @each $point, $value in $grid-breakpoints {
    .w-#{$point}-#{$size} {
      @include media-breakpoint-up($point) {
        width: $length;
        &[class*="flex-"] {
          flex: 0 0 $length;
        }
      }
    }
  }
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1030;
}

#webpack-dev-server-client-overlay {
  inset: 50% 0 0 50% !important;
  transform: translate(-50%, -50%);
  width: clamp(400px, 50vw, 800px) !important;
  height: clamp(400px, 80vh, 800px) !important;
}
