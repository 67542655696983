@import "~bootstrap/scss/variables";

.branch-node {
  padding: .25rem .5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14pt;

  &:hover {
    background-color: $gray-100;
  }

  .btn-toggle-expand {
    border: none !important;
    padding: .25rem .5rem;
  }

  .branch-name {
    color: $input-plaintext-color;
    text-decoration: none !important;
    padding: 0 .5rem;
    flex: 1;

    &:hover, &:focus, &:active {
      color: $link-hover-color;
    }
  }

  .btn-fav {
    border: none !important;
    padding: .25rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }

  .link-add-child {
    padding: .25rem .5rem;
    line-height: 1;
  }
}
