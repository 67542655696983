@import "~bootstrap/scss/mixins/breakpoints";

.me-page {
  max-height: 100%;
  overflow: auto;

  @include media-breakpoint-up('md') {
    padding: 2rem;
  }

  > .card {
    margin-bottom: 0;
  }

  @include media-breakpoint-down('sm') {
    > .card {
      border-radius: 0;
      border: none;
    }
  }
}
