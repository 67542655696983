@import "~bootstrap/scss/mixins/breakpoints";

.template {
  display: grid;
  grid-gap: 1rem;

  &.ingredient {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }

  &.process {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }

  &.lines {
    grid-template-columns: 1fr;

    .settings-block {
      display: flex;
      overflow: auto;

      .settings-item {
        display: grid;
        padding: 5px;
        min-width: 200px;
      }
    }
  }

  > .card {
    border: 0;
    box-shadow: 1px 1px 1px 2px lightgrey;
    margin: 0;

    .card-header {
      padding: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 1.1em;
      text-align: center;
      font-weight: 500;
      background-color: #008f95;
      color: white;
      border-bottom: 0;
    }

    .card-body {
      padding: 0;
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);

      ul {
        list-style: none;
        padding: 7px 0;

        li {
          padding: 3px 15px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e6e6e6;

          .item-name {
            font-weight: 400;
          }

          .item-quant {
            font-weight: 600;
          }
        }
      }

      .info-block {
        padding: 10px 15px;

        .info-item {
          display: flex;
          justify-content: space-between;
          padding: 0 25px;
        }
      }

      .settings-block {
        padding: 10px 15px;

        .settings-item {
          padding-bottom: 20px;
          display: flex;
          flex-direction: column;

          .title {
            display: inline-flex;
            align-self: baseline;
            padding: 3px 15px 1px;
            font-size: 1em;
            font-weight: 500;
            border-radius: 5px 5px 0 0;
            background-color: #e0e7eb;
            color: #212121;
            line-height: 1em;
          }

          .content {
            display: flex;
            flex: 1;
            flex-direction: column;
            border: 1px solid #e0e7eb;
            padding: 2px 5px;
            min-height: 50px;
            border-radius: 0 5px 5px 5px;

            > div {
              padding: 2px 7px;
              border-bottom: 1px solid lightgray;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &:last-child {
                border-bottom: none;
                padding-bottom: 10px;
              }

              small {
                background-color: #cceeff;
                padding: 2px 5px;
                margin-left: 5px;
                border-radius: 4px;
                max-width: 100px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    &:hover {
      cursor: pointer;

      .card-header {
        background-color: #00868c;
      }

      .card-body {
        background-color: #fafafa;
      }
    }
  }
}

@include media-breakpoint-down('sm') {
  .template {
    margin: 0 -20px;

    &.process, &.ingredient {
      grid-template-columns: 1fr;
    }

    > .card {
      border-radius: 0;

      .card-header {
        border-radius: 0;
      }
    }
  }
}
