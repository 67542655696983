@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "variables";

$hover-bg: #fafafa;
$active-bg: #f3f3f3;
$border-color: #eeeeee;

.page-production {
  flex: 1;
  height: 100%;
  overflow: auto;
  padding: 15px;
  position: relative;
  background-color: #fff;
  .output-main {
    font-weight: bold;
  }
}

.loader-fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba($white, 0.5);
  border-radius: 8px;
  border: 1px solid $white;
  .simple-loader {
    margin: 16px;
    border: 0.5em solid rgba($primary, 0.1);
    border-left-color: rgba($primary, 0.5);
    animation: load8 1s infinite linear;
  }
  .simple-loader, .simple-loader:after {
    width: 5em;
    height: 5em;
  }
}

.production-stage {
  padding: 8px 8px 8px 32px;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }
}

.production-stage-index {
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 4px;
  background-color: $success;
  overflow: visible;
  &:not(:last-child) {
    bottom: -1px;
  }
  > div:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);
    padding: 0 2px;
    border-radius: 8px;
    background-color: $success;
    font-size: 8px;
    line-height: 16px;
    text-align: center;
    font-weight: bold;
    color: $white;
  }
}

.production-stage-name {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 4px;
}

.production-processes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px -4px;
}

.production-process {
  margin: 4px;
  border-radius: 4px;
  border: 1px solid $primary;
  overflow: hidden;
  hr {
    margin: 4px 0;
    border-color: $primary;
  }
}

.production-process-name {
  padding: 4px 8px 2px;
  font-weight: bold;
}

.production-process-branch {
  padding: 2px 8px;
  font-size: smaller;
  color: $info;
  cursor: pointer;
  &:hover {
    background-color: $hover-bg;
  }
  &:active {
    background-color: $active-bg;
  }
  &.branch-selected {
    color: $dark;
  }
}

.production-ingredients {
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid $primary;
}

.production-ingredient-name {
  position: relative;
  padding: 2px 8px 2px 16px;
  font-size: smaller;
  cursor: pointer;
  &:hover {
    background-color: $hover-bg;
  }
  &:active {
    background-color: $active-bg;
  }
  &::before {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 8px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: $gray-200;
    content: ' ';
  }
  &.ingredient-selected::before {
    background-color: $primary;
  }
}

.process-cards {
  margin: 15px -8px;
  display: flex;
  flex-wrap: wrap;
}

.process-card {
  padding: 8px;
  width: 100%;
  @include media-breakpoint-up('md') {
    width: 50%;
  }
  @include media-breakpoint-up('xxl', (xxl: 2560px)) {
    width: 25%;
  }
  .card {
    margin-bottom: 0;
  }
}

.production-item-top {
  display: flex;
  align-items: center;
}

.production-branch {
  flex: 1;
}

.production-end-date, .production-start-date, .production-rec-date {
  padding: 0 4px;
  border: 1px solid;
  border-radius: 4px;
  margin-left: 4px;
}

.production-end-date {
  color: $success;
  border-color: rgba($success, 0.5);
}

.production-start-date {
  color: $warning;
  border-color: rgba($warning, 0.5);
}

.production-rec-date {
  color: $primary;
  border-color: rgba($primary, 0.5);
}

.production-line-name {
  font-size: large;
}

.production-stages-status {
  display: flex;
  flex-wrap: wrap;
  > span {
    align-self: center;
    display: flex;
    margin: 1px 4px;
    color: $gray-200;
  }
}

.production-stage-status {
  padding: 2px 0;
  &.stage-finished {
    color: $success;
  }
  &.stage-active {
    color: $primary;
  }
}

.process-output-name {
  flex: 1;
}

.process-output-actual {
  width: 25%;
  min-width: 100px;
}

// Manufacturing

$block-margin: 8px;
$block-padding: 8px;
$block-width: 250px;
$color-pending: $gray-400;

.manufacturing {
  margin: -20px;
  margin: -1.25rem;
  display: flex;
  flex-direction: column;
}

.item-production {
  display: flex;
  flex-direction: row;
  border-bottom: 4px solid $gray-200;
  &.running .production-info {
    background-color: $danger;
  }
  &.finished .production-info {
    background-color: $success;
  }
}

.production-info {
  width: $block-width;
  padding: $block-padding;
  background-color: $color-pending;
  color: $white;
  .line-name {
    font-weight: bold;
    font-size: larger;
  }
}

.production-stages {
  flex: 1;
  padding: $block-padding;
}

.item-stage {
  display: flex;
  &:first-child .item-process {
    border-top-width: 1px;
  }
  &.with-arrow {
    .stage-arrow::after {
      position: absolute;
      bottom: -$block-padding;
      left: 0;
      display: block;
      border-top: $block-padding solid transparent;
      border-right: $block-padding solid transparent;
      border-left: $block-padding solid transparent;
      width: 0;
      height: 0;
      content: ' ';
      z-index: 1;
    }
  }
  &.running {
    &.with-arrow .stage-arrow::after {
      border-top-color: $danger;
    }
    .stage-info {
      background-color: $danger;
    }
  }
  &.finished {
    &.with-arrow .stage-arrow::after {
      border-top-color: $success;
    }
    .stage-info {
      background-color: $success;
    }
  }
}

.stage-info {
  background-color: $color-pending;
  color: $white;
  width: $block-width;
  display: flex;
  flex-direction: row;
  > div:last-child {
    flex: 1;
    padding: $block-padding;
    border-left: 1px solid $white;
  }
}

.stage-arrow {
  position: relative;
  width: $block-padding*2;
}

.stage-processes {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.item-process {
  margin: $block-margin;
  padding: $block-padding;
  border: 1px solid $color-pending;
  border-bottom-width: $block-padding;
  &.running {
    border-color: $danger;
  }
  &.finished {
    border-color: $success;
  }
}
