@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.home-page-category {
  @include media-breakpoint-up(md) {
    border-radius: .5rem;
    width: calc(50% - 2rem);
    margin: 1rem;
    box-shadow: 0 4px 7px 0 rgba($black, .3);
  }
}

.home-page-category-heading {
  padding: .625rem 0 .5rem;
  font-size: 1.5em;
  font-weight: 600;
  color: $light;
  border-bottom: .125rem solid rgba($light, .3);
}

.menus-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 20px 10px;

  .menu-col {
    margin: 0;
    width: 30%;
    min-width: 100px;

    .home-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;

      &:hover {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.8);

        .nav-icon-1x1 {
          border: 2px solid rgba(255, 255, 255, 0.8);
          border-radius: 50%;
          background-color: rgba(0, 0, 0, .05);
        }
      }

      .nav-icon-1x1 {
        width: 100px;
        height: 100px;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-icon-t {
          font-style: normal;
          font-size: 50px;
          line-height: 50px;
          text-align: center;
        }
      }

      .home-menu-item-title {
        font-size: 1.1em;
        font-weight: 600;
        margin-top: 5px;
      }
    }
  }
}

.home-container {
  display: flex;
  background-color: $light;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
