.modal-dialog.modal-fixed-header {
  max-height: calc(100% - 56px);
  display: flex;
  > .modal-content {
    display: flex;
    flex: 1;
    > .modal-body {
      flex: 1;
      overflow: auto;
      min-height: 200px;
    }
  }
}

.modal-body.modal-bu-select-list {
  padding: 0;
}

.favorite_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  padding: 8px;
}

.favorite_item_line {
  border-bottom: 1px solid #000;
  height: 0;
  flex: 1;
}

.favorite_branch_item, .favorite_branch_user_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding: 4px 16px;
  &:not([aria-disabled=true]) {
    cursor: pointer;
    &:hover {
      background-color: #fafafa;
    }
    .node-tools {
      &, input[type=checkbox] {
        cursor: pointer;
      }
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  &[aria-disabled=true] {
    color: #777777;
  }
  .node-tools {
    margin: 0;
  }
}

.favorite_branch_item_user_label, .favorite_branch_item_branch_label {
  flex: 1;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
}

.favorite_item_label {
  margin: 0 8px;
}

.favorite_branch_item_text {
  font-weight: bold;
}

.favorite_branch_user_item_text_branch, .favorite_branch_item_text_parent {
  font-size: 10px;
  font-style: italic;
}

.all_branch_item, .all_branch_user_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #fafafa;
  }
  &[aria-disabled=true] {
    color: #777777;
  }
  .node-tools {
    margin: 0;
    &, input[type=checkbox] {
      cursor: pointer;
    }
  }
}

.all_branch_item {
  font-weight: bold;
  padding-right: 8px;
}

.all-bu-branch-name, .all-bu-user-name {
  flex: 1;
  margin-left: 8px;
}

.my_branches_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.my_branches_item_label {
  margin: 0 8px;
}

.my_branches_item_line {
  margin: 10px 0;
  border-bottom: 1px solid #000;
  flex: 1;
  height: 0;
}

.search_branch_item, .search_branch_user_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding: 4px 16px;
  &:not([aria-disabled=true]) {
    cursor: pointer;
    &:hover {
      background-color: #fafafa;
    }
    .node-tools {
      &, input[type=checkbox] {
        cursor: pointer;
      }
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  &[aria-disabled=true] {
    color: #777777;
  }
  .node-tools {
    margin: 0;
  }
}

.search_branch_item_branch_label, .search_branch_item_user_label {
  flex: 1;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
}

.search_branch_item_text {
  font-weight: bold;
}

.search_branch_user_item_text_branch, .search_branch_item_text_parent {
  font-size: 10px;
  font-style: italic;
}

.modal-bu-actions {
  flex-direction: row;
  .modal-title {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
}

.modal-bu-flex {
  flex: 1;
  & + .btn-toolbar {
    > .input-group, > .btn-link {
      margin-right: 8px;
    }
  }
}

.favorite-btn {
  display: flex;
  border-radius: 50%;
  padding: 8px;
  align-self: center;
  cursor: pointer;
  &:not(.favorite-btn-active) {
    color: #eee;
    &:hover {
      color: #aaa;
      background-color: #eee;
    }
  }
}

.favorite-btn-active {
  color: #ffd76e;
  &:hover {
    background-color: #fff7a3;
  }
}

.bu-select-form-single, .bu-select-form-single-branch, .bu-select-form-single-user {
}

.bu-select-form-items {
  margin: -2px;
  > .btn {
    margin: 2px;
  }
}

.bu-select-placeholder {
  &, &:hover {
    color: #e4e7ea;
  }
}
