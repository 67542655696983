.email-app {
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #c2cfd6;
  overflow: auto;
}

.email-app a:hover {
  text-decoration: none;
}

.email-app nav {
  flex: 0 0 200px;
  padding: 1rem;
  border-right: 1px solid #c2cfd6;
}

.btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}

.email-app nav .btn-block {
  margin-bottom: 15px;
}

.email-app nav .nav {
  flex-direction: column;
}

.email-app nav .nav .nav-item {
  position: relative;
}

.email-app nav .nav .nav-item .nav-link.active {
  background-color: #f0f3f5;
}

.email-app nav .nav .nav-item .nav-link, .email-app nav .nav .nav-item .navbar .dropdown-toggle, .navbar .email-app nav .nav .nav-item .dropdown-toggle {
  color: #151b1e;
  border-bottom: 1px solid #c2cfd6;
}

.email-app nav .nav .nav-item .nav-link i, .email-app nav .nav .nav-item .navbar .dropdown-toggle i, .navbar .email-app nav .nav .nav-item .dropdown-toggle i {
  width: 20px;
  margin: 0 10px 0 0;
  font-size: 14px;
  text-align: center;
}

.email-app nav .nav .nav-item .nav-link .badge, .email-app nav .nav .nav-item .navbar .dropdown-toggle .badge, .navbar .email-app nav .nav .nav-item .dropdown-toggle .badge {
  float: right;
  margin-top: 4px;
  margin-left: 10px;
}

.email-app main {
  min-width: 0;
  flex: 1;
}

.email-app .inbox {
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  .messages {
    padding: 0;
    margin: 0;
    list-style: none;
    flex: 1;
    overflow: auto;

    &:after {
      display: block;
      content: ' ';
      padding-top: 15px;
    }
  }

  .message {
    position: relative;
    padding: 1rem 1rem 1rem 3rem;
    cursor: pointer;
    border-bottom: 1px solid #c2cfd6;

    &:first-child {
      border-top: 1px solid #c2cfd6;

      &:before {
        top: -1px;
      }
    }

    &:hover {
      background-color: #f0f3f5;
    }

    &:before {
      background-color: #c2cfd6;
      width: 4px;
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: -1px;
    }

    &.task-urgent:before {
      background-color: #f86c6b;
    }
  }
}

.inbox .inbox-tools {
  padding: 15px 0;
}

.modal-body .message {
  &:not(:first-child) {
    border-top: 1px solid #c2cfd6;
  }

  &:hover {
    background-color: #f0f3f5;
  }
}

.email-app .inbox .message a {
  color: #000;
}

.email-app .inbox .message .actions {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
}

.email-app .inbox .message .actions .action {
  width: 2rem;
  margin-bottom: .5rem;
  color: #a4b7c1;
  text-align: center;
}

.email-app .inbox .message .actions .status.closed {
  color: green;
}

.email-app .inbox .message .actions .status.open {
  color: red;
}

.email-app .inbox .message .actions .action.rerouted {
  color: blue;
}

.email-app .inbox .message .header {
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
}

.email-app .inbox .message.unread .header, .email-app .inbox .message.unread .title {
  font-weight: 700;
}

.flex-modal-header .modal-title {
  flex: 1;

  & > .report-modal-header {
    display: flex;
    flex-direction: row;
  }
}

.modal-title-username {
  flex: 1;
}

.email-app .inbox .message .title {
  margin-bottom: .5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email-app .inbox .message .description {
  font-size: 12px;
}

.email-app .inbox .message .header .date {
  margin-left: auto;
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 1050 !important;
  }
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.status-box.status-box-issue-closed {
  background-color: #1f78d1;
}

.status-box.status-box-issue-open {
  background-color: #4dbd74;
}

.status-box {
  padding: 4px 8px;
  border-radius: 6px;
  color: #fff;
  margin-right: 8px;
}

.base-read-only {
  border-bottom: 1px solid #C2CFD6;
}

.task-history {
  padding: 0;
  overflow: hidden;
}

.timeline-entry-inner {
  padding: 15px;

  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
}

.flex-center-items {
  display: flex;
  align-items: center;
}

.aside-menu .message {
  display: block;
  padding: 16px;
  cursor: pointer;
  border-bottom: 1px solid #c2cfd6;

  &:hover, &:focus, &:active {
    background-color: #f0f3f5;
  }
}

.aside-menu {
  overflow-y: auto;
}

.rounded-item {
  border: 1px solid #c2cfd6;
  border-radius: 8px;
  padding: 1px 8px;
  margin: 0 8px 8px 0;
  display: inline-block;
}

.messages .message-title {
  font-size: large;
}

.messages .task-closed {
  text-decoration: line-through;
}

.messages .task-route-read {
  font-weight: lighter;
}

.route-status-icon {
  text-align: center;
  margin-left: 1rem;
}

.route-read {
  color: #20a8d8;
}

.route-done {
  color: green;
}

.route-approved {
  color: green;
}

.messages .task-route-unread {
  font-weight: bold;
}

.messages .task-assigner {
  color: #20a8d8;
}

.elements-number-indicator {
  padding: 6px;
  margin-right: 10px;

  .span {
    text-effect: emboss;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.slide-view {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;

  &.modal-dialog, > .modal-content, .modal-body {
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
  }

  .modal-body > div {
    height: 100%;

    > div:first-child {
      display: none;
    }

    > div:not(:first-child) {
      margin: 0;
      padding: 0;
      height: 100%;
    }
  }
}

.tasks-report-card {
  table thead th {
    text-align: center;
    vertical-align: middle;
  }
}

.task-files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
}

.tasks-file, .tasks-image {
  display: flex;
  position: relative;
  margin: 4px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;

  &:hover {
    border-color: #aaa;
  }

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    min-width: 100px;
    max-width: 200px;
    padding: 0;
    overflow: hidden;
    border: none;
    color: #eee;

    &:hover {
      color: #eee;
      opacity: 0.8;
    }
  }

  .btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.tasks-file .nav-link svg {
  width: 72px;
  height: 72px;
}

.tasks-file .nav-link .caption {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px;
  color: #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(255, 255, 255, 0.7);
}

.tasks-image .nav-link img {
  width: auto;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}
