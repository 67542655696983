@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.apx-image-list {
  display: flex;
  padding: .25rem 0;
  margin: 0 -.25rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.apx-image-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-100;
  border-radius: .25rem;
  overflow: hidden;
  cursor: zoom-in;
  text-decoration: none;
  margin: .25rem;

  &:hover, &:focus {
    background-color: $gray-300;
    opacity: .9;
    outline: none;
  }

  &:active {
    background-color: $gray-400;
    opacity: .9;
  }

  .apx-image {
    max-height: 100vh;

    @include media-breakpoint-up(md) {
      height: 100%;
      object-fit: contain;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
