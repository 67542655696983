@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "variables";

.react-pdf__Document {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  margin: 0 .5rem 0.5rem 0;
  width: 200px;
  @include media-breakpoint-up(sm) {
    width: 400px;
  }
  @include media-breakpoint-up(xl) {
    width: 600px;
  }
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
