.nav-main__content {
  margin: auto;
}

.full-height {
  height: 100%;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.ub-wrapper {
  padding: 15px;
  .ub-header {
    padding: 8px;
    font-size: 24px;
    font-weight: bold;
    color: #000;
  }
  .ub-home, .ub-ancestor, .ub-branch, .ub-current, .ub-user {
    border: 1px solid #C2CFD6;
    cursor: pointer;
    padding: 8px;
    &:hover {
      border-color: #a9b4ba;
      background-color: #e8f7ff;
    }
  }
  .ub-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ub-flex {
      flex: 1;
    }
  }
  .nav-tabs .nav-item {
    margin-bottom: -1px;
    text-align: center;
    display: table-cell;
  }
  .nav-tabs {
    padding: 0;
    display: table;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .node-tools > a.nav-link {
    display: inline-block;
    border: none;
  }
}